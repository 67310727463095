import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import jwt_decode from "jwt-decode";

const ProtectedRoute = ({children, user }) => {

    if (!user) {
        return <Navigate to="/login" replace />;
    } else {
        const userData = JSON.parse(user);
        const decoded = jwt_decode(userData.token);
        if (decoded.exp < (Date.now() / 1000)) {
            return <Navigate to="/login" replace/>;
        }
    }

    return children ? children : <Outlet/>;
};

export default ProtectedRoute
