import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../config";
const getPublicContent = () => {
    return axios.get(API_URL + "all");
};
const getStats = () => {
    return axios.get(API_URL + "stats")
};
const getUsers = () => {
    return axios.get(API_URL + "participants")
};
const getUser = (id) => {
    return axios.get(API_URL + "user-data/" + id, { headers: authHeader() })
};
const userDataUrl = (id) => {
    return API_URL + "participants/" + id
};
const updateUserDataUrl = (id) => {
    return API_URL + "participants/" + id
};

const UserService = {
    getPublicContent,
    getUsers,
    getUser,
    getStats,
    userDataUrl,
    updateUserDataUrl
};

export default UserService;
