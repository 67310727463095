import React from "react";
import _ from "lodash";
import {fieldTypes} from "../services/utils";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Slide,
    TextField
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";

const CustomField = ({formik, field}) => {
    const fieldType = _.find(fieldTypes, ['type', field.type])
    return(
        <Slide in={true} exit={true}
               children={<Grid item xs={12} md={field?.md || fieldType?.md || 6}>
                   {(() => {
                       switch (fieldType) {
                           case fieldTypes.CHECK_BOX:
                               return <FormControl fullWidth>
                                   <FormGroup>
                                       <FormControlLabel
                                           control={<Checkbox
                                               id={'form.' + field.id}
                                               name={'form.' + field.id}
                                               disabled={field.precompiled}
                                               checked={formik.values['form'][field.id]}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />} label={field.label}/>
                                   </FormGroup>
                               </FormControl>
                           case fieldTypes.SELECT:
                               return <FormControl variant="standard" fullWidth>
                                   <FormHelperText>{field.label}</FormHelperText>
                                   <Select
                                       //disabled={field.precompiled}
                                       label={field.label}
                                       id={'form.' + field.id}
                                       name={'form.' + field.id}
                                       //value={dati[field.id]}
                                       value={formik.values['form'][field.id]}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       //error={Boolean(formik.errors[field.id])}
                                       //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                       //helperText={field.label}
                                   >
                                       {field?.values?.map(v =>
                                           <MenuItem value={v} key={v}>{v}</MenuItem>
                                       )}
                                   </Select>
                               </FormControl>
                           case fieldTypes.DATE:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDatePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input"
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                                           formik.setFieldValue('form.' + field.id, date)
                                           formik.setFieldTouched('form.' + field.id)
                                       }}
                                       disabled={field.precompiled}
                                       value={new Date(formik.values['form'][field.id]) || ''}
                                       inputFormat="dd/MM/yyyy"/>
                               </LocalizationProvider>
                           default:
                               return <TextField disabled={field.precompiled}
                                                 id={'form.' + field.id}
                                                 name={'form.' + field.id}
                                   //value={dati[field.id]}
                                                 multiline={field.multiline}
                                                 value={formik.values['form'][field.id]}
                                                 onChange={formik.handleChange}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width:'100%'}}/>
                       }
                   })()}
               </Grid>}
        />)
}

export default CustomField
