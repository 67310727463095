import React, {useState, Suspense, useEffect} from "react";
import {
    Box, Button, Card,
    Container, Grid, Stack,
    Typography
} from "@mui/material";
import {DataGrid, GridToolbar, itIT} from '@mui/x-data-grid';
import UserService from "../services/user.service";
import PleshPieChart from "../components/PleshPieChart";
import {useNavigate} from "react-router-dom";
import logo from "../assets/logo.svg"
import {Cancel, Check, Close, HelpOutlineRounded, QueryStats, Remove} from "@mui/icons-material";
import jwt_decode from "jwt-decode";

const Home = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [stats, setStats] = useState([])
    const [kpis, setKpis] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [admin, setAdmin] = useState(false);
    const csvOptions = {allColumns: true, utf8WithBom: true}

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        allergie: false,
    });

    const columns = [
        {field: 'nome', headerName: 'Nome', flex: 1},
        {field: 'cognome', headerName: 'Cognome', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'location', headerName: 'Luogo', flex: 1},
        {field: 'telefono', headerName: 'Telefono', flex: 1},
        {field: 'citta', headerName: 'Città', flex: 1,
            valueGetter: (params) => {
                return params.row?.form?.citta ?? '';
            },
        },
        {field: 'regione', headerName: 'Regione', flex: 1,
            valueGetter: (params) => {
                return params.row?.form?.regione ?? '';
            },
        },
        {
            field: 'created_at', headerName: 'Data e ora iscrizione', flex: 1,
            type: 'dateTime',
            valueGetter: (params) => {
                return params.value ? new Date(params.value?._seconds * 1000) : '';
            },
        },
        {
            field: 'form_created_at', headerName: 'Data e ora form', flex: 1,
            type: 'dateTime',
            valueGetter: (params) => {
                return params.value ? new Date(params.value?._seconds * 1000) : '';
            },
        },
        {field: 'moderator', headerName: 'Moderatore Assegnato', flex: 1},
        {
            field: 'last_call_result', headerName: 'Esito Chiamata', flex: 1,
            valueGetter: (params) => {
                if (params.value) {
                    if (params.value === 'conferma') {
                        return "Conferma";
                    }
                    if (params.value === 'rifiuta') {
                        return "Rifiuta";
                    }
                    if (params.value === 'non-risposto') {
                        return "Senza Risposta";
                    }
                } else {
                    return '';
                }
                return params.value ? params.value : '';
            },
            renderCell: (params) => {
                if (params.value) {
                    if (params.value === 'Conferma') {
                        return <Check sx={{color: 'green'}}/>;
                    }
                    if (params.value === 'Rifiuta') {
                        return <Cancel sx={{color: 'darkred'}}/>
                    }
                    if (params.value === 'Senza Risposta') {
                        return <HelpOutlineRounded color={'disabled'}/>
                    }
                } else {
                    return <Remove color={'disabled'}/>;
                }
                return params.value ? params.value : '';
            }
        },
    ];

    useEffect(() => {
        fetchData().then()
    }, [])

    async function fetchData() {
        const response = await UserService.getUsers()
        setUsers(response.data)
        const userData = JSON.parse(localStorage.user);
        const decoded = jwt_decode(userData.token);
        if (decoded.email === 'dashboard@visionary.plesh.co') {
            setAdmin(true);
        }
        setLoading(false)
    }

    return (
        <main>

            <Box style={{background: '#000'}}>
                <Container maxWidth={false}>
                    <div style={{display: 'flex', width: '100%', maxWidth: '180px'}}>
                        <img src={logo} width={'100%'} style={{margin: '1rem 0'}}/>
                    </div>
                </Container>
            </Box>

            <Container maxWidth={false}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1, mt: 3}}>
                    <Typography variant={"h4"} sx={{textAlign: 'left'}}>Utenti Registrati</Typography>
                    {admin && <Button onClick={() => navigate('/stats')} variant={"outlined"} startIcon={<QueryStats />}>Statistiche Iscrizioni e Chiamate</Button>}
                </Box>
                <div style={{display: 'flex', height: 670, marginTop: '1rem'}}>
                    <div style={{flexGrow: 1}}>
                        <DataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'created_at', sort: 'desc'}],
                                },
                            }}
                            localeText={{
                                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                            }}
                            loading={loading}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            getRowId={(row) => row.email}
                            disableSelectionOnClick
                            onRowClick={(params, event, details) => {
                                navigate("/user/" + params.row.id);
                            }}
                            componentsProps={{toolbar: {csvOptions}}}
                            components={{Toolbar: GridToolbar}}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            columns={columns}
                            rows={users}
                        />
                    </div>
                </div>
            </Container>
        </main>
    )
}

export default Home
