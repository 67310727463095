import {CssBaseline, ThemeProvider} from "@mui/material";
import {Routes, Route} from "react-router-dom";
import './App.css';
import {pleshTheme} from "./theme/pleshTheme";
import Login from "./pages/Login";
import Home from "./pages/Home";
import User from "./pages/User";
import axios from "axios";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthService from "./services/auth.service";
import Stats from "./pages/Stats";

function App() {
    axios.interceptors.request.use(
        config => {
            const token = AuthService.getToken()
            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );

    return (
      <ThemeProvider theme={pleshTheme}>
          <CssBaseline/>

          <Routes>
              <Route path={'/login'} element={<Login/>}/>
              <Route element={<ProtectedRoute user={localStorage.user}/>}>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/stats'} element={<Stats/>}/>
                <Route path={'/user/:id'} element={<User/>}/>
              </Route>
          </Routes>

      </ThemeProvider>
  );
}

export default App;
