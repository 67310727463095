import React, {useState, Suspense, useEffect} from "react";
import {
    Box, Button, Card,
    Container, Grid, Stack,
    Typography
} from "@mui/material";
import UserService from "../services/user.service";
import {useNavigate} from "react-router-dom";
import logo from "../assets/logo.svg"
import {ArrowBack} from "@mui/icons-material";

const Stats = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [kpis, setKpis] = useState([])
    useEffect(() => {
        fetchData().then()
    }, [])

    async function fetchData() {
        const statsData = await UserService.getStats();
        setKpis(statsData.data);
        setLoading(false)
    }

    return (
        <main>

            <Box style={{background: '#000'}}>
                <Container maxWidth={false}>
                    <div style={{display: 'flex', width: '100%', maxWidth: '180px'}}>
                        <img src={logo} width={'100%'} style={{margin: '1rem 0'}}/>
                    </div>
                </Container>
            </Box>

            <Button sx={{m: 3}} variant={"outlined"} onClick={() => navigate('/')} startIcon={<ArrowBack />}>Home</Button>

            <Container maxWidth={false}>
                <Grid container spacing={3} sx={{pt: 1}}>
                    <Grid item xs={3}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Iscritti Totali</Typography>
                                <Typography variant={"h6"}>{kpis.total}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Form Completati</Typography>
                                <Typography variant={"h6"}>{kpis.fisico ? kpis.fisico + kpis.online : ''}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Form Fisici</Typography>
                                <Typography variant={"h6"}>{kpis.fisico}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={3}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Form Online</Typography>
                                <Typography variant={"h6"}>{kpis.online}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                <Typography variant={"h6"} sx={{mt: 3}}>Fisico</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Senza Risposta</Typography>
                                <Typography variant={"h6"}>{kpis.senzaRispostaFisico}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Conferma</Typography>
                                <Typography variant={"h6"}>{kpis.confermaFisico}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Rifiuta</Typography>
                                <Typography variant={"h6"}>{kpis.rifiutaFisico}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                <Typography variant={"h6"} sx={{mt: 3}}>Online</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Senza Risposta</Typography>
                                <Typography variant={"h6"}>{kpis.senzaRispostaOnline}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Conferma</Typography>
                                <Typography variant={"h6"}>{kpis.confermaOnline}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant={"body1"}>Rifiuta</Typography>
                                <Typography variant={"h6"}>{kpis.rifiutaOnline}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </main>
    )
}

export default Stats
