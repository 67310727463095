import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
    Box,
    Button,
    Card,
    Container,
    Fab,
    Grid,
    Snackbar, Stack, TextField,
    Typography,
    useTheme
} from "@mui/material";
import UserService from "../services/user.service";
import {
    ArrowBack,
    Article, Cancel, Check,
    CheckCircle,
    ContactSupport,
    ExpandMore,
    HelpOutlineRounded, PhoneMissed,
    Save, Send
} from "@mui/icons-material";
import {Alert, LoadingButton} from "@mui/lab";
import useAxios from "axios-hooks";
import CustomLoader from "../components/CustomLoader";
import formStructure from '../data/formStructure.json'
import userStructure from '../data/userStructure.json'
import CustomField from "../components/CustomField";
import {useFormik} from "formik";
import _ from 'lodash'
import logo from "../assets/logo.svg";
import axios from "axios";
import {API_URL} from "../config";

const User = () => {
    const {id} = useParams()
    const theme = useTheme()
    let navigate = useNavigate()

    const [loading, setLoading] = useState(true);
    const [calls, setCalls] = useState([])
    const [message, setMessage] = useState({show: false, text: "", severity: "success"})

    const [{data: userData, loading: loadingUserData, error: errorUserData}, fetchUser] = useAxios(
        UserService.userDataUrl(id), {method: "GET", useCache: false}
    )

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    const formik = useFormik({
        initialValues: userData,
        enableReinitialize: true,
        //validationSchema: // TODO
    });

    const isValid = () => {
        return formik.dirty && formik.isValid
    }

    const handleSubmitForm = () => {
        if (isValid()) {
            const dto = {}
            Object.keys(formik.touched).forEach((touchedField) => {
                if (formik.values[touchedField] !== formik.initialValues[touchedField])
                    if (touchedField === 'form') {
                        dto['form'] = {}
                        Object.keys(formik.touched.form).forEach((formField) => {
                            dto['form'][formField] = formik.values['form'][formField]
                        })
                    } else {
                        dto[touchedField] = formik.values[touchedField]
                    }
            })
            console.log(dto)

            if (!_.isEmpty(dto)) {
                updateUser({
                    data: dto,
                    url: UserService.updateUserDataUrl(id),
                    method: "POST"
                })
                    .then(() => setMessage({
                        ...message,
                        show: true,
                        text: "Aggiornamento effettuato con successo",
                        severity: "success"
                    }))
                    .catch((err) => {
                        setMessage({
                            ...message,
                            show: true,
                            text: "Aggiornamento non andato a buon fine",
                            severity: "error"
                        })
                    })
            }
        }
    }

    const addCall = async (status) => {
        setLoading(true)
        await axios.post(API_URL + 'participants/' + id + '/calls', {
            status: status,
            created_at: new Date()
        })
        setMessage({
            ...message,
            show: true,
            text: "Chiamata inserita con successo",
            severity: "success"
        })
        await fetchUser()
        await fetchData()
    }

    const sendForm = async (status) => {
        setLoading(true)
        await axios.post(API_URL + 'participants/' + id + '/send-form')
        setMessage({
            ...message,
            show: true,
            text: "Form reinviato con successo",
            severity: "success"
        })
        await fetchUser()
        await fetchData()
    }

    const fetchData = async () => {
        const res = await axios.get(API_URL + 'participants/' + id + '/calls');
        setCalls(res.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchData().then()
    }, [])

    return (
        <main>

            <Box style={{background: '#000'}}>
                <Container maxWidth={false}>
                    <div style={{display: 'flex', width: '100%', maxWidth: '180px'}}>
                        <img src={logo} width={'100%'} style={{margin: '1rem 0'}}/>
                    </div>
                </Container>
            </Box>

            <Container sx={{paddingY: '2rem'}}>
                <form onSubmit={handleSubmitForm}>
                    <Button LinkComponent={Link} to={"/"} variant="outlined" sx={{marginBottom: '2rem'}}
                            startIcon={<ArrowBack/>}>Torna all'elenco</Button>
                    {(loadingUserData || loadingUpdate) && <CustomLoader/>}
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography gutterBottom variant={"h4"}
                                    sx={{textAlign: 'left'}}>{userData?.nome} {userData?.cognome}</Typography>

                        {userData && userData.last_call_result && userData.last_call_result === 'conferma' &&
                            <Stack direction={"row"} alignItems={"center"}><CheckCircle color={"success"} fontSize={"large"} sx={{mr: 2}}/>
                                <Typography variant={"body1"}>Partecipazione Confermata</Typography></Stack>}

                        {userData && userData.last_call_result && userData.last_call_result === 'rifiuta' &&
                            <Stack direction={"row"} alignItems={"center"}><Cancel color={"error"} fontSize={"large"} sx={{mr: 2}}/>
                                <Typography variant={"body1"}>Non Partecipa</Typography></Stack>}

                    </Box>

                    <Alert severity="info">Location: <b>{userData?.location}</b></Alert>

                    {userData && !userData.form && <Alert sx={{mt: 2}} severity="error">Form non compilato</Alert>}

                    {userData && !userData.form
                        &&  <Card sx={{padding: '1rem', margin: '2rem 0'}} variant={'outlined'}>
                            <Typography variant={"h5"} sx={{mb: 2}}>Form</Typography>
                            <Typography variant={"body1"} sx={{mb: 1}}>
                                L'utente non ha compilato il form, puoi reinviarlo utilizzando il pulsante qui sotto:
                            </Typography>

                            <LoadingButton loading={loading} startIcon={<Send />} loadingPosition={"start"}
                                           variant={"outlined"} color={"info"}
                                           onClick={() => sendForm()}>Reinvia Form</LoadingButton>

                            <Typography variant={"body1"} sx={{mb: 1, mt: 2}}>
                                Oppure, inviargli direttamente questo link:
                            </Typography>

                            <TextField sx={{width: '100%'}} value={"https://form.visionarydays.com/iscrizione/" + id} variant="outlined" readonly={true} />
                        </Card>}

                    <Box mt={3} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography variant={"h5"}>Chiamate</Typography>
                    </Box>
                    <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                        <Typography variant={"h6"} sx={{textAlign: 'left', mb: 2}}>
                            Chiamate totali: {calls.length}
                        </Typography>
                        <Stack direction={"row"} alignItems={"center"} sx={{mb: 2}}>
                            {userData && userData.last_call_result === 'non-risposto' && <HelpOutlineRounded sx={{mr: 2}}/>}
                            {userData && userData.last_call_result === 'conferma' && <CheckCircle style={{color: theme.palette.success.dark}} sx={{mr: 2}}/>}
                            {userData && userData.last_call_result === 'rifiuta' && <Cancel style={{color: theme.palette.error.dark}} sx={{mr: 2}}/>}
                            <Typography variant={"body1"} sx={{textAlign: 'left'}}>
                                Ultima chiamata: {userData && userData.last_call_at ? new Date(userData.last_call_at).toLocaleString() : '-'}
                            </Typography>
                        </Stack>
                        <Typography variant={"body1"} sx={{textAlign: 'left', mb: 2}}>
                            Dopo aver chiamato il partecipante, seleziona l'esito:
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <LoadingButton loading={loading} startIcon={<PhoneMissed />} loadingPosition={"start"}
                                               variant={"outlined"} color={"info"}
                                        onClick={() => addCall('non-risposto')}>Non risposto</LoadingButton>
                            </Grid>
                            <Grid item xs={4}>
                                <LoadingButton loading={loading} startIcon={<Check />} loadingPosition={"start"}
                                               variant={"outlined"} color={"success"}
                                               onClick={() => addCall('conferma')}>Conferma</LoadingButton>
                            </Grid>
                            <Grid item xs={4}>
                                <LoadingButton loading={loading} startIcon={<Cancel />} loadingPosition={"start"}
                                               variant={"outlined"} color={"error"}
                                               onClick={() => addCall('rifiuta')}>Rifiuta</LoadingButton>
                            </Grid>
                        </Grid>
                    </Card>

                    <Box mt={3} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography variant={"h5"}>Dati utente</Typography>
                    </Box>
                    {
                        userData && formik.values &&
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Typography variant={"h6"} sx={{textAlign: 'left', mb: 2}}>Contatto</Typography>
                            <Grid container spacing={3}>
                                {
                                    userStructure.map((field, i) => {
                                        return <Grid item xs={12} md={field.id === 'note' ? 12 : 6}>
                                            <TextField disabled={field.precompiled}
                                                       id={field.id}
                                                       name={field.id}
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       multiline={field.multiline}
                                                       value={formik.values[field.id]}
                                                       label={field.label}
                                                       variant={"standard"}
                                                       sx={{width: '100%'}}/>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Card>
                    }
                    {
                        userData && userData.form && formik.values &&
                        <Card sx={{padding: '1rem', margin: '0.5rem 0'}} variant={'outlined'}>
                            <Typography variant={"h6"} sx={{textAlign: 'left', mb: 2}}>Form</Typography>
                            <Grid container spacing={3}>
                                {
                                    formStructure.map((field, i) => {
                                        if (field.id === 'frequenti')
                                            if (formik.values['occupazione'] !== 'Studentə')
                                                return <></>
                                        if (field.id === 'occupazioneSvolta')
                                            if (formik.values['occupazione'] !== 'Lavoratorə')
                                                return <></>
                                        if (field.id === 'frequentiScuola')
                                            if (formik.values['occupazione'] !== 'Studentə' || formik.values['frequenti'] !== 'Scuola')
                                                return <></>
                                        if (field.id === 'frequentiUniversita')
                                            if (formik.values['occupazione'] !== 'Studentə' || formik.values['frequenti'] !== 'Università')
                                                return <></>
                                        if (field.id === 'dieta' || field.id === 'intolleranza')
                                            if (userData['location'] !== 'fisico')
                                                return <></>
                                        if (field.id === 'taglia' || field.id === 'indirizzo' || field.id === 'cittaSpedizione' ||
                                            field.id === 'provinciaSpedizione' || field.id === 'cap' || field.id === 'destinatario' ||
                                            field.id === 'scalaInternoPiano' || field.id === 'ulterioriInformazioni')
                                            if (userData['location'] !== 'online')
                                                return <></>
                                        return <CustomField key={i} formik={formik} field={field}/>
                                    })
                                }
                            </Grid>
                        </Card>
                    }
                    <Fab //type="submit"
                        onClick={handleSubmitForm}
                        disabled={!isValid()}
                        color={'primary'}
                        sx={{
                            top: 'auto',
                            right: '20px',
                            bottom: '20px',
                            left: 'auto',
                            position: 'fixed',
                            zIndex: 1010,
                            //backgroundColor: 'lightgrey',
                        }}>
                        <Save/>
                    </Fab>
                </form>
            </Container>
            <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert} severity={message.severity}
                       sx={{width: '100%'}}>
                    {message.text}
                </Alert>
            </Snackbar>
        </main>
    )
}

export default User
